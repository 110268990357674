import logo from '../images/softurge_logo_black.svg';
import {Link, useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    function ScrollPage (div_id){
        const targetElement = document.getElementById(div_id);

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth',
          });
        }
    }

    return (
        <div id="footer-id" className="FooterBox">

            <div className="FooterBoxDiv">
                <div className="FooterBoxRight">
                    <div className="FooterLogo" onClick={event => navigate('/')}>
                        <img className="mobileFooterLogo" src={logo} alt=""/>
                    </div>

                    <div className="FooterBoxText1">
                        Reach out to us anytime and lets create a better future for all technology users together,
                        forever. We are open to all types of collab offers and tons more.
                    </div>
                </div>

                <div className="FooterLinks">
                    <div className="Link">Links</div>
                    <div className="LinksName">
                        <div className="LinksNameDiv" onClick={event => ScrollPage('about_us_button')}>About Us </div>
                        <div className="LinksNameDiv" onClick={event => ScrollPage('our_core_offerings_sliders')}> Services </div>
                        <div className="LinksNameDiv" onClick={event => ScrollPage('why_choose_us')}> Why Choose us? </div>
                        <div className="LinksNameDiv" onClick={event => ScrollPage('connect_with_us')}> Company Info </div>
                        <div className="LinksNameDiv" onClick={event => ScrollPage('tech_stack')}> Tech Stack </div>
                    </div>
                </div>

                <div className="FooterLinks">
                    <div className="Link">Contact us</div>
                    <div className="LinksName" style={{width: '33rem'}}>
                        Softurge<br/><br/>
                        connect@softurge.com<br/><br/>
                        G-173 Sushant Lok-2 Sector 57 Gurgaon Haryana 122003
                    </div>
                </div>


                <div className="FooterLinks2">
                    <div className="LinksName">
                    <span>Softurge connect@softurge.com<br/><br/>
                    G-173 Sushant Lok-2 Sector 57 Gurgaon Haryana 122003
                    </span>
                    </div>
                </div>

            </div>

            <div id="footer_id" className="FooterContent">
                <div className="FooterContentText">
                    © 2024 Copyright by Softurge. All rights reserved.
                </div>
            </div>

        </div>
    );
}

export default Footer;
